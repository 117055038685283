<script>
import { computed, ref, defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
import modal from '/~/core/mdl'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useLocalization } from '/~/composables/localization'

export default defineComponent({
  name: 'community-hub-offer-details',
  components: {
    BaseButton,
    BaseLoader,
  },
  props: {
    offer: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter()
    const { withAccessCheck } = useAccess()
    const { redeemOffer, fetchOfferGroupOffers, currentOfferGroupId } =
      useCommunityHub()
    const { translate } = useLocalization()

    const fetching = ref(false)
    const isRedeemed = ref(false)
    const uniqueCode = ref(null)

    const offerGroupId = computed(
      () => currentOfferGroupId.value ?? props.offer.offerGroupId
    )

    function showTermsModal() {
      modal.show('community-hub-offer-terms', {
        props: {
          terms: props.offer.terms,
        },
      })
    }

    async function redeem() {
      fetching.value = true

      try {
        const { data } = await redeemOffer(props.offer.id)

        uniqueCode.value = data.code

        await fetchOfferGroupOffers(offerGroupId.value)
        showRedeemedModal()
        isRedeemed.value = true
      } catch (error) {
        console.error('community-hub', error)
      } finally {
        fetching.value = false
      }
    }

    function onRedeemedButtonClick() {
      switch (props.offer.type) {
        case 'url':
          window.open(props.offer.promotionUrl, '_blank')
          break
        case 'coupon':
          showRedeemedModal()
          break
        default:
          showRedeemedModal()
      }
    }

    function onRedeemNowClick() {
      modal.show('confirm-choice-of-reward', {
        props: {
          title: 'Confirm choice of reward',
          description: props.offer.redirectContent,
          icon: 'plain/gift-v3',
          iconColor: 'text-primary',
        },
        on: {
          confirm: () => {
            redeem()
          },
        },
      })
    }

    const redeemedData = computed(() => {
      switch (props.offer.type) {
        case 'url':
          return {
            description: 'You are now being redirected to retailers website.',
            buttonText: 'Go to offer',
            singleButton: true,
            confirm: () => withAccessCheck({ callback: onRedeemedButtonClick }),
          }
        case 'coupon':
          return {
            code: uniqueCode.value,
            description: 'Use the code above during checkout to save ',
            buttonText: 'Done',
            singleButton: true,
            confirm: () => {
              router.push({ name: 'home' })
            },
          }
        case 'show':
          return {
            image: props.offer.redemptionImage,
            singleButton: true,
            buttonText: 'Done',
            confirm: () => {
              router.push({ name: 'home' })
            },
          }
      }
      return ''
    })

    function showRedeemedModal() {
      modal.show('reward-redeemed-modal', {
        props: {
          title: 'Reward redeemed',
          image: redeemedData.value.image,
          code: redeemedData.value.code,
          description: redeemedData.value.description,
          icon: 'plain/gift-v3',
          iconColor: 'text-primary',
          confirmButtonText: redeemedData.value.buttonText,
          singleButton: redeemedData.value.singleButton,
        },
        on: {
          confirm: () => {
            redeemedData.value.confirm()
          },
        },
      })
    }

    return {
      fetching,
      isRedeemed,
      showTermsModal,
      onRedeemNowClick,
      onRedeemedButtonClick,
      withAccessCheck,
      translate,
    }
  },
})
</script>

<template>
  <div
    v-if="offer"
    class="ml-5 rounded-lg border bg-white p-2.5 pb-0 text-base xs:pb-2.5 sm:ml-0 sm:border-none"
  >
    <div class="p-[15px]">
      <div class="items-center justify-between sm:flex">
        <div>
          <div class="font-bold text-eonx-neutral-800">
            {{ offer.name }}
          </div>
          <div class="mt-[5px]">
            <div
              v-if="offer.terms"
              class="mt-2.5 cursor-pointer text-base font-bold leading-7 text-primary underline opacity-75 hover:no-underline hover:opacity-100"
              @click="showTermsModal"
            >
              See offer Terms {{ translate('common.and') }} Conditions
            </div>
          </div>
        </div>
        <div class="py-5 text-center">
          <base-button
            v-if="offer.isRedeemed || isRedeemed"
            size="lge"
            @click="withAccessCheck({ callback: onRedeemedButtonClick })"
          >
            <span v-if="offer.type === 'url'">Go to offer</span>
            <span v-else>Reveal Code</span>
          </base-button>
          <base-button
            v-else-if="offer.canBeRedeemed"
            size="lge"
            @click="withAccessCheck({ callback: onRedeemNowClick })"
          >
            <base-loader v-if="fetching" size="xs" color="white" />
            <span v-else>Redeem Now</span>
          </base-button>
        </div>
      </div>

      <div
        v-if="offer.description"
        class="markup mt-[35px] border-t pt-[30px] text-eonx-neutral-800"
        v-html="offer.description"
      />
    </div>
  </div>
</template>
